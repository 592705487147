<template>
  <div class="loader">
    <div class="load">
      <hr />
      <hr />
      <hr />
      <hr />
    </div>
  </div>
</template>


<script>
export default {
  name: "loader"
}
</script>

<style scoped lang="scss">
@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.loader {
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background: $white;

  .load {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
  }

  .load hr {
    border: 0;
    margin: 0;
    width: 40%;
    height: 40%;
    position: absolute;
    border-radius: 50%;
    animation: spin 2s ease infinite;
  }

  .load :first-child {
    background: #005a85;
    animation-delay: -1.5s;
  }

  .load :nth-child(2) {
    background: #b5d16c;
    animation-delay: -1s;
  }

  .load :nth-child(3) {
    background: #00a070;
    animation-delay: -0.5s;
  }

  .load :last-child {
    background: #317575;
  }

  @keyframes spin {
    0%,
    100% {
      transform: translate(0);
    }
    25% {
      transform: translate(160%);
    }
    50% {
      transform: translate(160%, 160%);
    }
    75% {
      transform: translate(0, 160%);
    }
  }
}
</style>