import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"
import {compile} from "vue-template-compiler";


Vue.use(Vuex)

const url = 'https://cbg.teva.ua';
// const url = 'https://cbg.inchkiev.ua';

const itemOnPage = 20;

export default new Vuex.Store({

  state: {

    bgMusicTime: 0,
    sound: true,
    totalHeight: '0px',
    menu: false,

    rowData: null,

    dataForFilters: null,

    gamePopup: false,

    rowContent: {
      data: null,
      page: 1
    },
    filterNos: null,
    currentProduction: null,
    moreProduction: null,

    loader: true,

    answerData: {
      img: null,
      linkText: null,
    },

    allFilter: true,

    nosFilter: null,

    recipe: {
      isActive: false,
      disable: false,
    },
    nonRecipe: {
      isActive: false,
      disable: false,
    },
    pharm: {
      isActive: false,
      disable: false,
    },

    pageNum: [1],

    articleNosologies: null,

    articles: null,

    article: null,
    articleNum: [1],
    relatedArticle: [],
    relatedDrugs: [],

    quizHubStage: 1,
    quizData: [
      {
        id: '01',
        name: 'Шолом-захист від алергії',
        disable: false,
        quiz: {
          question: [
            {
              name: "Пилкової алергії",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Бронхіту",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Фотодерматиту",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Кропив’янки",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "На фестивалі шоломів цей точно посів би одне з призових місць через свій футуристичний вигляд і важливу функцію, яку він виконував.\n" +
            "На початку 80-х років британський архітектор Річард Хінчліфф винайшов спеціальний шолом, який був схожий на бульбашку<sup>1</sup>. Як вважаєте, для лікування чого його використовували?",
          img1: "01-1.jpg",
          text2: 'Річард Хінчліфф стверджував, що шолом захищає від пилку рослин, запобігаючи появі симптомів алергії. Цей пристрій складався з вентилятора на батарейках, повітряного фільтра і прозорого пластикового шолома-бульбашки, який робив власника схожим на космонавта. Було продано майже 600 таких апаратів, їх показували на першій сторінці журналу National Geographic у жовтні 1984 року. Однак його було важко носити в теплі літні місяці, і він заважав у розмовах, тому не став популярним для лікування пилкової алергії<sup>1</sup>.',
          img2: "01-2.jpg",

          text3: {
            text1: "Добре, що в Лінійці бренду Тева є Дезлоратадин-Тева й Цетиризин-Тева, які допоможуть вплинути на симптоми алергічного риніту та кропив’янки<sup>2,3</sup> без дивних незручних шоломів!",
            items: [
              {
                title1: "Дезлоратадин-Тева",
                text2: "",
                text3: "показаний для усунення симптомів, пов’язаних з алергічним ринітом та кропив’янкою<sup>2</sup>",
                img3: "01-3.png",
              },
              {
                title1: "Цетиризин-Тева ",
                text2: '',
                text3: "показаний для симптоматичної терапії<sup>3</sup>:" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Сезонного та постійного алергічного риніту, а також неназальних симптомів, пов’язаних із кон’юнктивітом</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Свербежу та кропив’янки різних типів, включаючи хронічну ідіопатичну кропив’янку</span>" +
                  "</p>",
                img3: "01-4.png",
              }
            ],

            links: [
              "1.Prototype of the ‘Hincherton Hayfever Helmet’. Available at: <a href='https://collection.sciencemuseumgroup.org.uk/objects/co8639689/prototype-of-the-hincherton-hayfever-helmet-prototype-object-genre' target='_blank'>https://collection.sciencemuseumgroup.org.uk/objects/co8639689/prototype-of-the-hincherton-hayfever-helmet-prototype-object-genre</a> (Last access: 17.12.2023).",
              "2.Інформація про лікарських засобів РП №UA/18739/01/01, №UA/7158/01/01",
              "<br>Дезлоратадин-Тева: 1 таблетка, вкрита плівковою оболонкою, містить дезлоратадину 5 мг.",
              "Характеристики: Блакитні круглі, діаметром 6 мм двоопуклі таблетки, вкриті плівковою оболонкою, з тисненням «LT» з одного боку. Антигістамінний засіб для системного застосування. Лікувальні властивості: Симптоматична терапія алергічного риніту і кропив’янки.",
              "Можливі побічні реакції: головний біль, сухість у роті, втомлюваність. Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу.",
              "Цетиризин-Тева: 1 таблетка містить цетиризину дигідрохлориду 10 мг",
              "Характеристики: Круглі двоопуклі таблетки, вкриті плівковою оболонкою, від білого до майже білого кольору, з лінією для поділу з одного боку.",
              "Лікувальні властивості: Антигістамінний засіб для системного застосування. Симптоматична терапія алергічного риніту, свербежу і кропив’янки.",
              "Можливі побічні реакції: сонливість, підвищена втомлюваність, запаморочення та головний біль. Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу.",
              "Для професійної діяльності медичних/фармацевтичних працівників.",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
              "CBG-UA-01329",
            ]
          },
        }
      },
      {
        id: '02',
        name: 'Природна АСК',
        disable: false,
        quiz: {
          question: [
            {
              name: "Верба",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Дуб",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Оливкове дерево",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Береза",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "Згідно з давньогрецькою міфологією, уславлений співець та музикант Орфей носив гілки цього дерева для захисту від злих сил під час мандрів підземним світом<sup>1</sup>. А у графстві Ессексі, що на сході Англії, люди вішали ці гілки на двері перед своїми будинками для відлякування болотяних відьом<sup>2.</sup><br>Стародавні шумери та єгиптяни використовували кору цього дерева як засіб від болю, гарячки та запалення<sup>3</sup>. Як вважаєте, про яке дерево йдеться?",
          img1: "02-1.jpg",
          text2: 'Кора верби використовується як лікарський засіб вже понад 3500 років. Стародавні цивілізації, які першими виявили властивості кори, не знали про те, що активною речовиною в ній був саліцин. Згодом саме він став основою для відкриття ацетилсаліцилової кислоти<sup>3</sup>. Але коли її почали використовувати в низьких дозах не як протизапальний засіб, а для зменшення ризику смерті у пацієнтів із серцево-судинними захворюваннями? У 1975 р. з’ясували, що ацетилсаліцилова кислота пригнічує агрегацію тромбоцитів шляхом блокування синтезу тромбоксану А<sub>2</sub><sup>4</sup>. Таким чином, ці ліки посіли надважливе місце в кардіологічній практиці.',
          img2: "02-2.jpg",

          text3: {
            text1: "Шкода, що у Стародавньому світі не знали про всі властивості верби та не мали АСК-Тева, що застосовують для зниження ризику смерті від серцево-судинних захворювань!<sup>5</sup>",
            items: [
              {
                title1: "Для зниження ризику<sup>5</sup>:",
                text2: "",
                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Смерті у пацієнтів із підозрою на гострий ІМ, та які перенесли ІМ</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Транзиторних ішемічних атак й інсульту</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Захворюваності й смерті при стабільній і нестабільній стенокардії</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>ІМ у пацієнтів із високим ризиком розвитку серцево-судинних ускладнень</span>" +
                  "</p>",

                text4: "Для профілактики<sup>5</sup>:",
                text5: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Тромбозів і емболій після операцій на судинах</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Тромбозу глибоких вен і емболії легеневої артерії після довготривалої іммобілізації</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Інсультів</span>" +
                  "</p>",
                img3: "02-3.png",
              },
            ],

            links: [
              "ІМ — інфаркт міокарда",
              "1. Willow tree symbolism and significance. Available at: <a href='https://www.betterplaceforests.com/blog/our-forests/willow-tree-symbolism-and-significance/' target='_blank'>https://www.betterplaceforests.com/blog/our-forests/willow-tree-symbolism-and-significance/</a> (Last access: 13.12.2023).",
              "2. Tales and a Tune of the Willow. Available at: <a href='https://www.metmuseum.org/blogs/in-season/2015/tales-and-a-tune-of-the-willow' target='_blank'>https://www.metmuseum.org/blogs/in-season/2015/tales-and-a-tune-of-the-willow</a> (Last access: 13.12.2023).",
              "3. Norn S., Permin H., Kruse P.R., Kruse E. Fra pilebark til acetylsalicylsyre [From willow bark to acetylsalicylic acid]. Dan Medicinhist Arbog. 2009; 37:79-98. Danish. PMID: 20509453.",
              "4. Desborough M.J.R., Keeling D.M. The aspirin story – from willow to wonder drug. Available at: <a href='https://onlinelibrary.wiley.com/doi/10.1111/bjh.14520' target='_blank'>https://onlinelibrary.wiley.com/doi/10.1111/bjh.14520</a> (Last access: 13.12.2023).",
              "5. Інформація про лікарський засіб РП №№UA/14167/01/01, №UA/14167/01/02. Характеристики: таблетки по 75 мг: білі, овальні, двоопуклі, кишковорозчинні таблетки, з розмірами 9,2×5,2 мм;",
              "таблетки по 100 мг: білі, круглі, двоопуклі, кишковорозчинні таблетки, 7,2 мм в діаметрі",
              "<br>Лікувальні властивості: Антитромботичний засіб. Для зниження ризику смерті при гострому інфаркт міокарда; транзиторних ішемічних атак (ТІА) та інсульту; вторинна профілактика інсультів і інфаркта міокарда у пацієнтів високого ризику.",
              "Можливі побічні реакції: подовження часу кровотечі; мікрокровотечі (70%), шлункові симптоми, біль у животі, печія. Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу.",
              "Для професійної діяльності медичних/фармацевтичних працівників. ",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284. ",
              "CBG-UA-01329",
            ]
          },
        }
      },
      {
        id: '03',
        name: 'Зілля проти болю',
        disable: false,
        quiz: {
          question: [
            {
              name: "Настій кореня мандрагори",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Зілля «Косторіст»",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Мімбулус мімбелтонія",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Жаброслі",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "Того дня Гаррі Поттер прокинувся ще вдосвіта і якийсь час лежав, думаючи про сьогоднішній матч із квідичу. Він ще ніколи так сильно не хотів перемоги над Слизерином. Натовп збуджено загудів, і 14 гравців злетіли в свинцеве небо. Гаррі\n" +
            "здійнявся вище за всіх і примружився, шукаючи снитча. Він гасав довкола стадіону, ніби на американських гірках, і не помітив бладжера, який влучив йому в лікоть. Він гепнувся в калюжу і знепритомнів, його рука неприродно стирчала. Коли професор Локарт спробував зцілити руку, він випадково перетворив її на немовби товсту гумову рукавичку тілесного кольору без кісток. Мадам Помфрі аж ніяк цьому не зраділа та попередила Гаррі, що відростити кістки можливо, але це болюча процедура<sup>1</sup> . Як вважаєте, який засіб для цього використала мадам Помфрі у шкільній лікарні?",
          img1: "03-1.jpg",
          text2: 'Мадам Помфрі налила в мензурку паруючу рідину з великої пляшки з написом Skele-gro і подала її Гаррі. Вона попередила його, що ніч буде тяжкою, адже відрощування кісток — не вельми приємний процес. Зілля обпалило Гаррі рот і горло. Він почав кашляти й захлинатися, але Рон із Герміоною допомагали йому запивати ліки водою. Врешті-решт Рон засяяв усмішкою і сказав: «А все ж ми виграли!»<sup>1</sup>',
          img2: "03-2.jpg",

          text3: {
            text1: "Шкода, що у мадам Помфрі не було ще й Диклофенак-Тева Форте 2%, який має виражені знеболювальні та протизапальні властивості<sup>2</sup>. Він міг би допомогти Гаррі Поттеру так само, як допомагає вашим пацієнтам сьогодні!",
            items: [
              {
                title1: "Гель Диклофенак-Тева Форте 2% показаний для<sup>2</sup>:",
                text2: "",

                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Лікування болю, запалення та набряку при:</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>— </span>" +
                  "<span class='text'>Пошкодженні м’яких тканин: травми сухожиль, зв’язок, м’язів, суглобів та біль у спині</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>— </span>" +
                  "<span class='text'>Локалізованих формах ревматизму м’яких тканин: тендиніт, бурсит, плечовий синдром та періартропатія</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Симптоматичного лікування остеоартриту дрібних та середніх суглобів, що розташовані поверхнево, таких як суглоби пальців або колінні суглоби</span>" +
                  "</p>",
                img3: "03-3.png",
              }
            ],

            links: [
              "1. Джоан К. Ролінґ. Гаррі Поттер і таємна кімната. Режим доступу: <a href='https://ukrlib.com.ua/world/printitzip.php?tid=3944' target='_blank'>https://ukrlib.com.ua/world/printitzip.php?tid=3944</a> (дата звернення: 14.12.2023).",
              "2. Інформація про лікарський засіб РП №UA/19275/01/01. Характеристики: Однорідний кремоподібний гель від білого до майже білого кольору.",
              "<br>Лікувальні властивості: Засіб, що застосовують місцево у разі суглобового та м’язового болю. Нестероїдний протизапальний засіб місцевого застосування. Лікування болю, запалення та набряку при пошкодженні м’яких тканин і локалізованих формах ревматизму м’яких тканин; симптоматичне лікування остеоартриту дрібних та середніх суглобів, що розташовані поверхнево.",
              "Можливі побічні реакції: дерматит (зокрема контактний дерматит), висип, еритема, екзема, свербіж. Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу.",
              "Для професійної діяльності медичних/фармацевтичних працівників.",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
              "CBG-UA-01329"
            ]
          },
        }
      },
      {
        id: '04',
        name: 'Секретні протигрибкові властивості',
        disable: false,
        quiz: {
          question: [
            {
              name: "Коріння рослин",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Мох",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Павутиння",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Кора дерев ",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "Коли перші промені сонця торкалися стародавніх стін Колізею, він оживав, готуючись до чергового дня видовищ. Його арена була свідком безлічі гладіаторських боїв, де воїни ризикували життям заради слави. Гладіатори часто поверталися з арени з глибокими пораненнями. Лікарі Римської імперії застосовували спеціальні пов’язки, які, на їхню думку, мали виражені антисептичні та протигрибкові властивості<sup>1,2</sup>. Як вважаєте, який матеріал використовували лікарі Стародавнього Риму для пов’язок?",
          img1: "04-1.jpg",
          text2: 'У Стародавньому Римі лікарі використовували павутиння для виготовлення пов’язок, щоб лікувати поранення. Вважалося, що павутиння має природні антисептичні та протигрибкові властивості, які допомагають підтримувати рани в чистоті та запобігати інфекціям. Також була думка, що павутиння сприяє згортанню крові. Можливо, це пояснюється високим вмістом вітаміну К в павутинні, але за сучасними стандартами медицини цей метод лікування не може використовуватись.<br>Спочатку лікарі очищували рани медом та оцтом, а потім обгортали їх павутинням<sup>1,2</sup>.',
          img2: "04-2.jpg",

          text3: {
            text1: "А яку зброю вибрав би гладіатор для приборкання грибкової інфекції? Можливим варіантом був би Флуконазол-Тева",
            items: [
              {
                title1: "Флуконазол-Тева — протигрибковий засіб для системного застосування у Лінійці бренду Тева.<sup>3</sup>",
                img3: "04-3.png",
              },
            ],

            links: [
              "1. Bandages. Available at: <a href='https://www.loredohands.com/blog/924351-bandages' target='_blank'>https://www.loredohands.com/blog/924351-bandages</a> (Last access: 12.12.2023).",
              "2. Fascinating Uses of Spider Silk. Available at: <a href='https://www.discovermagazine.com/planet-earth/5-fascinating-uses-of-spider-silk' target='_blank'>https://www.discovermagazine.com/planet-earth/5-fascinating-uses-of-spider-silk</a> (Last access: 13.12.2023).",
              "3. Інформація про лікарський засіб РП №UA/16524/01/03. 1 капсула тверда містить флуконазолу 150 мг",
              "<br>Характеристики: Тверді желатинові капсули, наповнені білим або жовтувато-білим однорідним порошком, з непрозорою кришечкою та корпусом блакитного кольору.",
              "Лікувальні властивості: Протигрибковий препарат для системного застосування. Гострий вагінальний кандидоз або кандидозний баланіт, коли місцева терапія не є доцільною.",
              "Можливі побічні реакції: головний біль, біль у животі, діарея, нудота, блювання, висипання, підвищення рівня аланінамінотрансферази (АЛТ), аспартатамінотрансферази (АСТ), лужної фосфатази крові. Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу.",
              "Для професійної діяльності медичних/фармацевтичних працівників. ",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
              "CBG-UA-01329",
            ]
          },
        }
      },
      {
        id: '05',
        name: 'Крижане серце',
        disable: false,
        quiz: {
          question: [
            {
              name: "Інфаркт міокарда",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Фібриляція передсердь",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Дилатаційна кардіоміопатія",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Перикардит",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "Посередині безкінечної снігової зали було замерзле озеро. Там сиділа Снігова Королева. Вона казала, що сидить на дзеркалі розуму і що це найкраще дзеркало в світі. Маленький Кай зовсім посинів, майже почорнів від холоду, але не помічав цього. Поцілунки Снігової Королеви зробили його нечутливим, а серце хлопця стало крижаним. Коли Герда знайшла Кая, вона вигукнула: «Кай! Милий мій Кай! Нарешті я знайшла тебе!». Та він сидів тихий, непорушний і холодний.<br>Тоді Герда заплакала, її гарячі сльози впали Каю на груди та розтопили крижану скалку чарівного дзеркала у його серці<sup>1</sup>.<br>Як вважаєте, з яким захворюванням серцево-судинної системи можна порівняти цю казку?",
          img1: "05-1.jpg",
          text2: 'Інфаркт міокарда, немов крижана скалка у серці, спричиняє гострий пекучий біль, який триває >20 хв і не зникає після сублінгвального прийому нітрогліцерину або інших нітратів<sup>2</sup>.<br>Згідно з міжнародними та українськими рекомендаціями, у постінфарктний період передбачається прийом антитромботичних препаратів для зниження ризику серцево-судинних ускладнень<sup>3,4</sup>. Одним із таких препаратів з Лінійки бренду Тева є АСК-Тева<sup>5</sup>.',
          img2: "05-2.jpg",

          text3: {
            text1: "АСК-Тева пригнічує агрегацію тромбоцитів шляхом блокування синтезу тромбоксану А<sub>2</sub>, завдяки чому його застосовують при багатьох серцево-судинних захворюваннях!<sup>5</sup>",
            items: [
              {
                title1: "Для зниження ризику<sup>5</sup>:",
                text2: "",
                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Смерті в пацієнтів із підозрою на гострий ІМ, та які перенесли ІМ</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Транзиторних ішемічних атак й інсульту</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Захворюваності й смерті при стабільній і нестабільній стенокардії</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>ІМ у пацієнтів із високим ризиком розвитку серцево-судинних ускладнень</span>" +
                  "</p>",

                text4: "Для профілактики<sup>5</sup>:",
                text5: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Тромбозів і емболій після операцій на судинах</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Тромбозу глибоких вен й емболії легеневої артерії після довготривалої іммобілізації</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Інсультів</span>" +
                  "</p>",
                img3: "05-3.png",
              },
            ],

            links: [
              "ІМ — інфаркт міокарда",
              "1. Андерсен Ганс Крістіан. Снігова королева. Режим доступу: <a href='https://www.ukrlib.com.ua/world/printit.php?tid=260' target='_blank'>https://www.ukrlib.com.ua/world/printit.php?tid=260</a> (дата звернення: 16.01.2024).",
              "2. Гострі коронарні синдроми (ГКС). Режим доступу: <a href='https://empendium.com/ua/chapter/B27.II.2.5.2' target='_blank'>https://empendium.com/ua/chapter/B27.II.2.5.2</a> (дата звернення: 17.01.2024).",
              "3. Уніфікований клінічний протоколекстреної, первинної, вторинної (спеціалізованої), третинної(високоспеціалізованої) медичної допомоги та кардіореабілітації. Гострийкоронарний синдром з елевацією сегмента ST. Наказ МОЗ України №1936 від 14.09.2021.",
              "4. 2023 ESC Guidelines for the management of acute coronary syndromes. Available at: <a href='https://www.escardio.org/Guidelines/Clinical-Practice-Guidelines/Acute-Coronary-Syndromes-ACS-Guidelines' target='_blank'>https://www.escardio.org/Guidelines/Clinical-Practice-Guidelines/Acute-Coronary-Syndromes-ACS-Guidelines</a> (Last access: 17.01.2024).",
              "5. Інструкція для медичного застосування лікарського засобу АСК-Тева, таблетки, по 75 та 100 мг. РП №UA/14167/01/01, №UA/14167/01/02.",
              "<br>Інформація про лікарський засіб. Для професійної діяльності медичних і фармацевтичних працівників. ",
              "Характеристики: 1 таблетка містить кислоти ацетилсаліцилової 75 мг або 100 мг.",
              "Лікувальні властивості: антитромботичні засоби.",
              "Найчастішими побічними реакціями є: мікрокровотечі (70%), шлункові симптоми, біль у животі, печія тощо.",
              "Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу АСК-Тева.",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
              "CBG-UA-01354",
            ]
          },
        }
      },
      {
        id: '06',
        name: 'Зміїна отрута',
        disable: false,
        quiz: {
          question: [
            {
              name: "Дигоксин",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Каптоприл",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Аміодарон",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Лабетолол",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "З давніх-давен змія вважалася тотемом відродження. Починаючи з часів Асклепія, давньогрецького бога медицини, вона була символом лікарської справи. За повір’ям Асклепій носив із собою посох, обвитий змією. Вважалося, що Асклепій здатен вилікувати всі хвороби і навіть воскрешати мертвих зміїною отрутою<sup>1</sup>.<br>Як вважаєте, який лікарський засіб був виготовлений із отрути бразильської гадюки? ",
          img1: "06-1.jpg",
          text2: 'Каптоприл був синтезований із отрути бразильської гадюки <i>Bothrops Jararaca.</i> Нобелівський лауреат Джон Вейн спочатку протестував пептиди з отрути на легенях собак та виявив, що вони здатні блокувати активність АПФ. Згодом він запропонував дослідити докладніше цю діючу речовину компанії <i>Bristol Myers Squibb.</i> Після багатьох досліджень Девід Кушман і Мігель Ондетті у 1975 році офіційно представили перший пероральний інгібітор АПФ — каптоприл<sup>2,3</sup>.',
          img2: "06-2.jpg",

          text3: {
            text1: "Добре, що в сучасній практиці використовувати отруту змій уже не потрібно, оскільки в Лінійці бренду Тева є Раміприл-Тева — препарат для лікування артеріальної гіпертензії та серцевої недостатності!",
            items: [
              {
                title1: "Показання Раміприл-Тева<sup>4</sup>:",
                text2: "",
                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Артеріальна гіпертензія</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Початкова та виражена клубочкова діабетична нефропатія</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Симптомна серцева недостатність</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Вторинна профілактика після перенесеного гострого інфаркту міокарда</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Зниження серцево-судинної захворюваності та летальних випадків у пацієнтів із вираженим серцево-судинним захворюванням атеротромботичного генезу, діабетом</span>" +
                  "</p>" +
                  "",
                img3: "06-3.png",
              }
            ],

            links: [
              "АПФ — ангіотензинперетворюючий фермент",
              "1. Tsoucalas G., Androutsos G. Chapter 17 - Asclepius and the Snake as Toxicological Symbols in Ancient Greece and Rome, Editor(s): Philip Wexler, In History of Toxicology and Environmental Health, Toxicology in Antiquity (Second Edition), Academic Press. 2019:257-265. <a href='https://doi.org/10.1016/B978-0-12-815339-0.00017-2' target='_blank'>https://doi.org/10.1016/B978-0-12-815339-0.00017-2</a>",
              "2. 10 heart drugs with weird origins. Available at: <a href='https://www.bhf.org.uk/informationsupport/heart-matters-magazine/medical/drug-cabinet/heart-drugs-with-weird-origins' target='_blank'>https://www.bhf.org.uk/informationsupport/heart-matters-magazine/medical/drug-cabinet/heart-drugs-with-weird-origins</a> (Last access: 03.01.2024). ",
              "3. Koh D.C., Armugam A., Jeyaseelan K. Snake venom components and their applications in biomedicine. Cell Mol Life Sci. 2006 Dec;63(24):3030-41. DOI: 10.1007/s00018-006-6315-0",
              "4. Інструкція для медичного застосування лікарського засобу Раміприл-Тева, таблетки по 5 та 10 мг. РП №UA/16689/01/02, №UA/16689/01/03. ",
              "<br>Інформація про лікарський засіб. Для професійної діяльності медичних/фармацевтичних працівників. ",
              "Характеристики: 1 таблетка містить раміприлу 5 мг або 10 мг.",
              "Лікувальні властивості: інгібітори ангіотензинперетворювального ферменту (АПФ). Інгібітори АПФ монокомпонентні. Раміприл. ",
              "Найчастішими побічними реакціями є: головний біль, запаморочення, непродуктивний подразнювальний кашель, бронхіт, синусит, задишка, запальні явища у шлунково-кишковому тракті, розлади травлення, дискомфорт у животі, диспепсія, діарея, нудота, блювання, макулопапульозні висипання, підвищення рівня калію у крові, артеріальна гіпотензія, ортостатичне зниження артеріального тиску, синкопе, біль у грудях. ",
              "Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу Раміприл-Тева. ",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
              "CBG-UA-01348 "
            ]
          },
        }
      },
      {
        id: '07',
        name: 'Гриби безсмертя',
        disable: false,

        quiz: {
          question: [
            {
              name: "Ловастатин",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Валсартан",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Добутамін",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Еналаприл",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "У древньому Китаї вважали, що гриби зміцнюють здоров’я людини та продовжують молодість. Греки припускали, що гриби надають сили воїнам у запеклих боях. Народ Майя використовували їх у релігійних обрядах. <br>А у Стародавньому Єгипті гриби вважалися рослинами безсмертя і були подаровані людству богом відродження — Осірісом. Найпоширенішим культивованим грибом в Єгипті був плеврот черепичастий або глива<sup>1</sup>.<br>Як вважаєте, який лікарський засіб був виділений із деревного їстівного гриба — гливи? ",
          img1: "07-1.jpg",
          text2: 'Історія статинів бере свій початок у Японії. У 1970 році японський мікробіолог Акіра Ендо виділив з гриба <i>Penicillium citrinum</i> першу відому сполуку, що знижувала рівень холестерину — мевастатин, який був інгібітором ГМК-КоА-редуктази. Проте він був занадто токсичним, щоб давати його людям.<br>Згодом у 1978 році американський біохімік Альфред Альбертс виділив ловастатин із плеврота черепичастого. Це стало основою для розробки багатьох сучасних статинів. У природі він міститься в гливах — <i>Aspergillus terreus і Pleurotus ostreatus,</i> червоному дріжджовому рисі та пуері<sup>2</sup>.',
          img2: "07-2.jpg",

          text3: {
            text1: "Добре, що тепер не потрібно розраховувати на гриби. У Лінійці бренду Тева є Розувастатин-Тева та Аторвастатин-Тева, які застосовують для лікування гіперхолестеринемії та профілактики серцево-судинних порушень!<sup>3,4</sup>",
            items: [
              {
                title1: "Показання<sup>3,4</sup>:",
                text2: "",
                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Лікування гіперліпідемії</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Профілактика серцево-судинних захворювань</span>" +
                  "</p>",
                img3: "07-3.png",
              }
            ],

            links: [
              "1. Ahmed A.F., Ghada Abd-Elmonsef Mahmoud, Hefzy M. et al. Overview on the edible mushrooms in Egypt, Journal of Future Foods, 2023; 3(1):8-15. <a href='https://doi.org/10.1016/j.jfutfo.2022.09.002' target='_blank'>https://doi.org/10.1016/j.jfutfo.2022.09.002</a>",
              "2.  10 heart drugs with weird origins. Available at: <a href='https://www.bhf.org.uk/informationsupport/heart-matters-magazine/medical/drug-cabinet/heart-drugs-with-weird-origins' target='_blank'>https://www.bhf.org.uk/informationsupport/heart-matters-magazine/medical/drug-cabinet/heart-drugs-with-weird-origins</a> (Last access: 03.01.2024). ",
              "3.  Інструкція для медичного застосування лікарського засобу Аторвастатин-Тева, таблетки по 10 та 20 мг. РП №UA/16377/01/01, №UA/16377/01/02. ",
              "4. Інструкція для медичного застосування лікарського засобу Розувастатин-Тева, таблетки, по 5 мг, 10 або 20 мг. РП №UA/19505/01/01, №UA/19505/01/02, №UA/19505/01/03.",
              "<br>Інформація про лікарські засоби. Для професійної діяльності медичних і фармацевтичних працівників.Аторвастатин-Тева (1 таблетка містить аторвастатину  10 або 20 мг). Характеристики: таблетки по 10 мг: білі або майже білі, еліптичні, двоопуклі, гладенькі таблетки, розміром приблизно 9,7 мм × 5,2 мм.; таблетки по 20 мг: білі або майже білі, еліптичні, двоопуклі, гладенькі таблетки, розміром приблизно 12,5 мм × 6,6 мм.",
              "Лікувальні властивості: препарати, що знижують рівень холестерину і тригліцеридів у сироватці крові. Інгібітори ГМГ-КоА-редуктази. Найчастішими побічними реакціями є: міалгія, діарея, нудота, підвищення рівня аланінамінотрансферази (АлАТ) та печінкових ферментів. ",
              "Розувастатин-Тева (1 таблетка містить розувастатину 5 мг, 10 або 20 мг). Характеристики: таблетки по 5 мг: круглі двоопуклі таблетки жовтого кольору, вкриті плівковою оболонкою, гладкі з обох сторін;",
              "таблетки по 10 мг та 20 мг: круглі двоопуклі таблетки рожевого кольору, вкриті плівковою оболонкою, з лінією розлому з однієї сторони та гладкі з іншої сторони.",
              "Лікувальні властивості: гіполіпідемічні засоби. Інгібітори ГМГ-КоА-редуктази. Найчастішими побічними реакціями є: головний біль, запаморочення, запор, нудота, абдомінальний біль, міалгія, астенія, дозозалежне зростання рівнів креатинкінази, дозозалежне збільшення рівня трансаміназ, протеїнурія.",
              "Повний перелік побічних реакцій міститься в інструкціях для медичного застосування лікарських засобів Аторвастатин-Тева та Розувастатин-Тева.",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
              "CBG-UA-01329",
            ]
          },
        }
      },

      {
        id: '08',
        name: 'Срібний місяць',
        disable: false,
        quiz: {
          question: [
            {
              name: "Заїкання",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Хвороба Альцгеймера",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Епілепсія",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Депресивні розлади",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "З давніх-давен повний місяць асоціювався з лунатизмом та божевільною поведінкою, включаючи самогубство, незаконну діяльність, напади насильства і міфи про перевертнів. Дійсно, слово «божевільний» (лат. lunaticus) походить від імені римської богині Місяця — Луни, яка, за переказами, щоночі проїжджала на срібній колісниці по темному небу<sup>1</sup>. Алхіміки пов’язували срібло з місяцем і згодом почали використовувати його в медицині<sup>2,3</sup>.<br>Як вважаєте, для лікування якого захворювання застосовували срібло? ",
          img1: "08-1.jpg",
          text2: 'Згідно з легендою, одного разу в епілептика припинилися напади судом після того, як він проковтнув велику срібну монету, яку використовували для запобігання прикушуванню язика. Згодом нітратом срібла почали лікувати епілепсію<sup>2</sup>.<br>Однак надмірна кількість цієї речовини спричиняла появу незворотного сірого забарвлення шкіри, тому згодом нітрат срібла перестали використовувати<sup>3</sup>.',
          img2: "08-2.jpg",

          text3: {
            text1: "Добре, що більше не потрібно вдаватися до екзотичних препаратів зі срібла, оскільки в Лінійці бренду Тева є Прегабалін-Тева, який зменшує частоту судомних нападів вже на першому тижні лікування!<sup>4</sup>",
            items: [
              {
                title1: "Показання<sup>4</sup>: ",
                text2: "",
                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Нейропатичний біль периферичного або центрального походження у дорослих</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Як додаткове лікування при парціальних судомних нападах із вторинною генералізацією або без неї</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Генералізований тривожний розлад</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Фіброміалгія</span>" +
                  "</p>",
                img3: "08-3.png",
              }
            ],

            links: [
              "1. 7 Unusual Myths and Theories About the Moon. Available at: <a href='https://www.history.com/news/7-unusual-myths-and-theories-about-the-moon' target='_blank'>https://www.history.com/news/7-unusual-myths-and-theories-about-the-moon</a> (Last access: 13.01.2024). ",
              "2. Alexander J.W. History of the medical use of silver. Surg Infect (Larchmt). 2009; 10(3):289-92. DOI: 10.1089/sur.2008.9941",
              "3. Royal Society of Chemistry. Epilepsy - beyond bromide. Available at: <a href='https://edu.rsc.org/feature/epilepsy-beyond-bromide/2020135.article' target='_blank'>https://edu.rsc.org/feature/epilepsy-beyond-bromide/2020135.article</a> (Last access: 13.01.2024). ",
              "4. Інструкція для медичного застосування лікарського засобу Прегабалін-Тева, капсули тверді по 75 та 150 мг. РП №UA/13629/01/01, №UA/13629/01/02. ",
              "<br>Інформація про лікарський засіб. Для професійної діяльності медичних і фармацевтичних працівників. ",
              "Характеристики: 1 капсула містить прегабаліну 75 або 150 мг.",
              "Лікувальні властивості: протиепілептичні засоби, інші протиепілептичні засоби.",
              "Найчастішими побічними реакціями є: назофарингіт, посилення апетиту, ейфоричний настрій, сплутаність свідомості, дратівливість, дезорієнтація, безсоння, зниження лібідо, запаморочення, сонливість, головний біль, атаксія, порушення координації, тремор, дизартрія, амнезія, погіршення пам'яті, розлади уваги, парестезія, гіпестезія, седація, порушення рівноваги, летаргія.  ",
              "Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу Прегабалін-Тева. ",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
              "CBG-UA-01329",
            ]
          },
        }
      },
      {
        id: '09',
        name: 'Мрія русалоньки',
        disable: false,
        quiz: {
          question: [
            {
              name: "Облітеруючий атеросклероз",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Хвороба Паркінсона",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Варикозна хвороба",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Нейропатичний біль",
              correct: true,
              disable: false,
              select: false,
            }
          ],

          text1: "Далеко у відкритому морі, де вода зовсім синя, наче пелюстки волошок, і прозора, наче скло, живуть русалки. Із шести сестер найкрасивішою була наймолодша. Ніжна, з синіми очима, ніби глибоке море, вона, як і всі русалки, мала хвіст замість ніг. Одного дня русалка закохалася у принца і була готова на все, лише щоб бути з ним. Тож вона поплила до бурхливого виру, за яким жила відьма. Чарівниця здійснила бажання русалки — перетворила її хвіст на ноги. Проте при кожному кроці русалці здавалося, ніби вона ступає по гострих голках та ножах. Але дівчина охоче терпіла це заради принца<sup>1</sup>.<br>Як вважаєте, для якого захворювання характерні схожі симптоми?",
          img1: "09-1.jpg",
          text2: 'Нейропатичний біль спричиняють захворювання соматосенсорної системи, включаючи периферичні волокна і центральні нейрони. До поширених станів, асоційованих із нейропатичним болем, належать постгерпетична невралгія, невралгія трійчастого нерва, больова радикулопатія, діабетична нейропатія. Пацієнти зазвичай мають такі симптоми: печіння, поколювання, біль у нижніх кінцівках, схожий на удар електричним струмом, а також біль внаслідок небольових стимуляцій. Вони зберігаються тривалий час і мають тенденцію до хронізації<sup>2</sup>.',
          img2: "09-2.jpg",

          text3: {
            text1: "Добре, що в Лінійці бренду Тева є Прегабалін-Тева, який зменшує нейропатичний біль вже після першого тижня лікування і є чудовим розв’язанням цієї проблеми!<sup>3</sup>",
            items: [
              {
                title1: "Показання<sup>3</sup>: ",
                text2: "",
                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Нейропатичний біль периферичного або центрального походження у дорослих</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Як додаткове лікування при парціальних судомних нападах із вторинною генералізацією або без неї</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Генералізований тривожний розлад</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Фіброміалгія</span>" +
                  "</p>",
                img3: "09-3.png",
              }
            ],

            links: [
              "1. Андерсен Ганс Крістіан. Русалонька. Режим доступу: <a href='https://tales.org.ua/rusalonka/' target='_blank'>https://tales.org.ua/rusalonka/</a> (дата звернення: 15.01.2024). ",
              "2. Нейропатичний біль: лекція для лікарів первинної ланки медичної допомоги. Режим доступу: <a href='https://umj.com.ua/uk/publikatsia-134648-nejropatichnij-bil-lektsiya-dlya-likariv-pervinnoyi-lanki-medichnoyi-dopomogi#list' target='_blank'>https://umj.com.ua/uk/publikatsia-134648-nejropatichnij-bil-lektsiya-dlya-likariv-pervinnoyi-lanki-medichnoyi-dopomogi#list</a> (дата звернення: 15.01.2024). ",
              "3.  Інструкція для медичного застосування лікарського засобу Прегабалін-Тева, капсули тверді по 75 та 150 мг. РП №UA/13629/01/01, №UA/13629/01/02. ",
              "<br>Інформація про лікарський засіб. Для професійної діяльності медичних і фармацевтичних працівників. ",
              "Характеристики: 1 капсула містить прегабаліну 75 або 150 мг.",
              "Лікувальні властивості: протиепілептичні засоби, інші протиепілептичні засоби.",
              "Найчастішими побічними реакціями є: назофарингіт, посилення апетиту, ейфоричний настрій, сплутаність свідомості, дратівливість, дезорієнтація, безсоння, зниження лібідо, запаморочення, сонливість, головний біль, атаксія, порушення координації, тремор, дизартрія, амнезія, погіршення пам'яті, розлади уваги, парестезія, гіпестезія, седація, порушення рівноваги, летаргія.",
              "Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу Прегабалін-Тева. ",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
              "CBG-UA-01329",
            ]
          },
        }
      },
      {
        id: '10',
        name: 'Пневмостат',
        disable: false,
        quiz: {
          question: [
            {
              name: "Бронхіальна астма",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Силікоз",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Муковісцидоз",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Пневмонія",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "На початку 1930-х років медицина переживала час великих відкриттів. У цей період традиційні підходи до лікування поступово відступали перед новітніми технологіями та науковими дослідженнями. Зокрема спеціальний портативний електричний пристрій використовували як небулайзер для розпилення ліків. Його називали «Пневмостат»<sup>1</sup>.<br>На вашу думку, для лікування якого захворювання використовували цей пристрій?",
          img1: "10-1.jpg",
          text2: 'Пневмостат був виготовлений компанією <i>Francis Riddell Limited</i>  у Лондоні та використовувався для лікування бронхіальної астми. Він мав регульовану швидкість та важив 5 кг. Зазвичай пацієнти інгалювали Бронховідрин, який містив папаверину гідрохлорид, атропін, аметокаїну гідрохлорид, адреналін та екстракт гіпофіза<sup>1,2</sup>.',
          img2: "10-2.jpg",

          text3: {
            text1: "Які чудернацькі винаходи людство тільки не придумує в пошуках нових методів лікування!<br>Добре, що в Лінійці бренду Тева є Будесонід-Тева, який має протизапальну дію та ефективно застосовується для лікування бронхіальної астми!<sup>3</sup>",
            items: [
              {
                title1: "Показаний для лікування<sup>3</sup>:",
                text2: "",
                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Бронхіальної астми у пацієнтів, для яких застосування інгаляторів із розпиленням лікарських засобів стиснутим повітрям або у вигляді лікарської форми сухого порошку є неефективним або недоцільним</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Крупу в немовлят та дітей</span>" +
                  "</p>",
                img3: "10-3.png",
              }
            ],

            links: [
              "1. Asthma Management. A brief overview of asthma and its treatment. Available at: <a href='https://museum.aarc.org/galleries/asthma-management/' target='_blank'>https://museum.aarc.org/galleries/asthma-management/</a> (Last access: 04.01.2024). ",
              "2. Cartwright A., Armstrong A. A History of the Medicines We Take: From Ancient Times to Present Day. Available at: <a href='https://www.google.com.ua/books/edition/A_History_of_the_Medicines_We_Take/ltveDwAAQBAJ?hl=en&gbpv=1&dq=A+History+of+the+Medicines+We+Take:+From+Ancient+Times+to+Present+Day&printsec=frontcover' target='_blank'>https://www.google.com.ua/books/edition/A_History_of_the_Medicines_We_Take/ltveDwAAQBAJ?hl=en&gbpv=1&dq=A+History+of+the+Medicines+We+Take:+From+Ancient+Times+to+Present+Day&printsec=frontcover</a> (Last access: 04.01.2024). ",
              "3. Інструкція для медичного застосування лікарського засобу Будесонід-Тева, суспензія для розпилення, 0,25 мг/мл або 0,5 мг/мл. РП №UA/18925/01/01, №UA/18925/01/02. ",
              "<br>Інформація про лікарський засіб. Для професійної діяльності медичних/фармацевтичних працівників. ",
              "Характеристики: 1 мл суспензії для розпилення містить будесоніду 0,25 мг або 0,5 мг. ",
              "Лікувальні властивості: інгаляційні засоби, що застосовуються для лікування обструктивних захворювань дихальних шляхів. Глюкокортикоїди. ",
              "Найчастішими побічними реакціями є: орофарингеальний кандидоз, пневмонія, кашель, подразнення горла, захриплість, подразнення слизової оболонки ротової порожнини, утруднення ковтання. ",
              "Із повним переліком побічних реакцій можна ознайомитися в інструкції для медичного застосування лікарського засобу Будесонід-Тева. ",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
              "CBG-UA-01329",
            ]
          },
        }
      },

      {
        id: '11',
        name: 'Темне минуле психіатрії',
        disable: false,
        quiz: {
          question: [
            {
              name: "Гідротерапія",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Фітотерапія",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Гірудотерапія",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Фототерапія",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "XIX століття було часом великих інновацій у сантехніці. Міста отримали першу сучасну каналізацію з тунелями, що простягалися на кілометри під землею. Психіатричні «притулки» не стали виключенням. Із появою цих благ цивілізації психіатри почали активно застосовувати специфічний метод лікування своїх пацієнтів<sup>1</sup>.<br>Як вважаєте, який метод терапії ментальних захворювань використовували у психіатричних «притулках»?",
          img1: "11-1.jpg",
          text2: 'Ще у ХVII столітті голландський хімік і лікар Ян Баптист ван Гельмонт занурював пацієнтів у воду для лікування ментальних захворювань. Його надихнула історія про божевільного, який після того, як ледь не потонув, «оговтався» і нібито видужав. На рубежі XIX століття лікарі вважали, що мозок — це джерело безумства. Тож замість того, щоб занурювати все тіло у воду, вони почали спрямовувати струмінь води на голову пацієнта, щоб її «охолодити». Також пацієнтам робили теплі ванни, які тривали годинами, а то й днями. Їх щільно загортали у мокрі простирадла, потім обгортали ще одним гумовим покривалом, щоб вони потіли кілька годин. Лікарі мали різні наукові пояснення такої терапії: це знімало застійні явища в мозку або таким чином виводилися токсини, які спричиняли божевілля<sup>1</sup>.',
          img2: "11-2.jpg",

          text3: {
            text1: "Добре, що у лікарів з’явилися нові лікарські засоби, зокрема Есциталопрам-Тева та Мемантин-Тева, які ефективно лікують різноманітні ментальні захворювання!<sup>2,3</sup>",
            items: [
              {
                title1: "Показаний для лікування<sup>3</sup>:",
                text2: "",
                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Великих депресивних епізодів</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Панічних розладів із/без агорафобії</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Соціальних тривожних розладів</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Генералізованих тривожних розладів</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Обсесивно-компульсивних розладів</span>" +
                  "</p>",
                img3: "11-3.png",
              },
              {
                title1: "Показаний для лікування хвороби Альцгеймера від середнього ступеня тяжкості до тяжких форм<sup>3</sup>",
                text2: "",
                text3: "",
                img3: "11-4.png",
              }
            ],

            links: [
              "1. Showering Has a Dark, Violent History. Available at: <a href='https://www.theatlantic.com/health/archive/2018/12/dark-history-of-showering/577636/' target='_blank'>https://www.theatlantic.com/health/archive/2018/12/dark-history-of-showering/577636/</a> (Last access: 05.01.2024). ",
              "2. Інструкція для медичного застосування лікарського засобу Есциталопрам-Тева, таблетки, вкриті плівковою оболонкою, по 10 та 20 мг. РП №UA/11732/01/02, №UA/11732/01/04. ",
              "3. Інструкція для медичного застосування лікарського засобу Мемантин-Тева, таблетки, вкриті плівковою оболонкою, по 10 та 20 мг. РП №UA/19083/01/01, №UA/19083/01/02. ",
              "<br>Інформація про лікарські засоби. Для професійної діяльності медичних і фармацевтичних працівників. Есциталопрам-Тева (1 таблетка містить есциталопраму 10 мг або 20 мг).Характеристики: таблетки 10 мг: білі, круглі, двоопуклі, вкриті плівковою оболонкою таблетки з тисненням «10» з одного боку таблетки та лінією розлому з іншого боку таблетки. Тріщини або сколи не спостерігаються. Поділка призначена не для розламування таблетки на дві однакові дози, а для полегшення ковтання; таблетки 20 мг: білі, круглі, двоопуклі, вкриті плівковою оболонкою таблетки з лінією розлому на одному з боків, тисненням «9» зліва та «3» справа від лінії розлому. На другому боці наявне тиснення «7463», без видимих тріщин або сколів. Таблетку можна розділити по поділці на дві однакові частини.",
              "Лікувальні властивості: антидепресант, селективний інгібітор зворотного захоплення серотоніну (СІЗЗС). ",
              "Найчастішими побічними реакціями є: головний біль, безсоння, сонливість, запаморочення, нудота, діарея, запор, блювання, сухість у роті, зниження або посилення апетиту.",
              "Мемантин-Тева (1 таблетка, вкрита плівковою оболонкою, містить мемантину гідрохлориду 10 мг або 20 мг). Характеристики: таблетки по 10 мг: білі двоопуклі таблетки у формі капсули, вкриті плівковою оболонкою, з тисненнями «М» та «10» на одній стороні, що розділені лінією розлому, з розміром 9,8 × 4,9 мм. Таблетку можна розділити на дві рівні дози; таблетки по 20 мг: темно-рожеві овальні, двоопуклі таблетки, вкриті плівковою оболонкою, з тисненням «М 20» на одній стороні, з розміром 12,6 × 7 мм.",
              "Лікувальні властивості: психоаналептик, потенціалзалежний, середньої афінності неконкурентний антагоніст NMDA-рецепторів, засіб для застосування у разі деменції. ",
              "Найчастішими побічними реакціями є: запаморочення, головний біль, запор, сонливість та артеріальна гіпертензія.",
              "Повний перелік побічних реакцій міститься в інструкціях для медичного застосування лікарських засобів Есциталопрам-Тева та Мемантин-Тева. ",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
              "CBG-UA-01329",
            ]
          },
        }
      },
      {
        id: '12',
        name: 'Лікувальні мурахи ',
        disable: false,
        quiz: {
          question: [
            {
              name: "Артеріальна гіпертензія",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Бронхіальна астма",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Виразкова хвороба",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Гіпотиреоз",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "Попри маленький розмір та непомітність їх широко застосовують у народній медицині в усьому світі. Саме так, йдеться про мурах! Австралійських ткацьких мурах використовують для лікування сильного кашлю, застуди та грипу<sup>1</sup> . Як вважаєте, для лікування якого захворювання застосовують мурах у Латинській Америці?",
          img1: "12-1.jpg",
          text2: 'У Латинській Америці отруту неотропічних мурах <i>Dinoponera</i> використовують для лікування бронхіальної астми<sup>1</sup>.',
          img2: "12-2.jpg",

          text3: {
            text1: "Добре, що в Лінійці бренду Тева є Монтелукаст-Тева, який покращує клінічний контроль бронхіальної астми у дітей та дорослих, не потребуючи використання мурах<sup>2,3</sup>!",
            items: [
              {
                title1: "Показання<sup>2,3</sup>:",
                text2: "",
                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Додаткове лікування при БА у пацієнтів із персистуючою астмою від легкого до середнього ступеня, яка недостатньо контролюється інгаляційними кортикостероїдами та агоністами β-адренорецепторів короткої дії</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Профілактика БА, домінуючим компонентом якої є бронхоспазм, індукований фізичним навантаженням</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Полегшення симптомів сезонного та цілорічного алергічного риніту</span>" +
                  "</p>",
                img3: "12-3.png",
              }
            ],

            links: [
              "БА — бронхіальна астма",
              "1. Lauren Seabrooks, Longqin Hu. Insects: an underrepresented resource for the discovery of biologically active natural products, Acta Pharmaceutica Sinica B. 2017; 7(4):409-426. ISSN 2211-3835. <a href='https://doi.org/10.1016/j.apsb.2017.05.001' target='_blank'>https://doi.org/10.1016/j.apsb.2017.05.001</a>",
              "2. Інструкція для медичного застосування лікарського засобу Монтелукаст-Тева, таблетки жувальні по 4 мг або 5 мг. РП №UA/12439/01/01, №UA/12439/01/02.",
              "3. Інструкція для медичного застосування лікарського засобу Монтелукаст-Тева, таблетки, вкриті плівковою оболонкою, по 10 мг. РП №UA/12439/02/01. ",
              "<br>Інформація про лікарський засіб. Для професійної діяльності медичних і фармацевтичних працівників. ",
              "Характеристики: 1 таблетка жувальна містить монтелукасту 4 мг або 5 мг у вигляді монтелукасту натрію; 1 таблетка, вкрита плівковою оболонкою, містить монтелукасту 10 мг у вигляді монтелукасту натрію.",
              "Лікувальні властивості: блокатор лейкотрієнових рецепторів, пригнічує ранню та пізню фази бронхоконстрикції.",
              "Найчастішими побічними реакціями є: головний біль, абдомінальний біль, відчуття спраги, інфекція верхніх дихальних шляхів. ",
              "Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу Монтелукаст-Тева. ",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
              "CBG-UA-01375"
            ]
          },
        }
      },

      {
        id: '13',
        name: 'Таємниці лікування кашлю у Стародавньому Єгипті',
        disable: false,

        quiz: {
          question: [
            {
              name: "Молоко",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Мак",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Льон",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Мед",
              correct: true,
              disable: false,
              select: false,
            }
          ],

          text1: "На зорі цивілізації, коли береги Нілу були під владою могутніх фараонів, таємничі жерці Стародавнього Єгипту закладали основи майбутньої медицини. Вони застосовували найрізноманітніші методи лікування, від лікарських трав до перших спроб хірургічних втручань та протезування. Як вважаєте, який лікарський засіб був найпопулярнішим у Стародавньому Єгипті? ",
          img1: "13-1.jpg",
          text2: 'Згідно з єгипетською міфологією, бджоли виникли із сліз бога сонця Ра, коли вони приземлилися на пісок пустелі. Саме тому бджола вважалася священною комахою, яка сполучала дійсний світ природи із потойбічним, а мед «рідким золотом», що захоронювали у гробниці разом з фараонами<sup>1</sup>.<br>Мед був найпопулярнішим «лікарським засобом» у Древньому Єгипті. Його часто змішували з вином і молоком та приносили у жертву своїм божествам, а також використовували для бальзамування померлих. Вважалося, що мед має антибактеріальні властивості, тому його використовували не лише як мазь для загоєння інфікованих ран, а й для лікування кашлю<sup>2,3</sup>.',
          img2: "13-2.jpg",

          text3: {
            text1: "Шкода, що у єгипетських жерців не було Стоптусин-Тева з широкої Лінійки бренду Тева, який використовують для лікування сухого кашлю. Стоптусин-Тева міг би допомогти людям у давнині, як допомагає Вашим клієнтам сьогодні!",
            items: [
              {
                title1: "Показання<sup>3,4</sup>:",
                text2: "",
                text3: "Комбінований препарат Стоптусин-Тева показаний для лікування сухого подразливого нападоподібного кашлю різного походження<sup>4</sup>.",
                img3: "13-3.png",
              }
            ],

            links: [
              "1. Historical Honey: Ancient Egypt. Available at: <a href='https://localhivehoney.com/blogs/blog/historical-honey-ancient-egypt' target='_blank'>https://localhivehoney.com/blogs/blog/historical-honey-ancient-egypt</a>  (Last access: 06.11.2023).",
              "2. Eteraf-Oskouei T, Najafi M. Traditional and modern uses of natural honey in human diseases: a review. Iran J Basic Med Sci. 2013 Jun;16(6):731-42. PMID: 23997898; PMCID: PMC3758027.",
              "3. Magical honey: some unusual uses in Ancient Egypt. Available at: https://the-past.com/feature/magical-honey-some-unusual-uses-in-ancient-egypt/ (Last access: 12.12.2023). ",
              "4. Інструкція для медичного застосування лікарського засобу Стоптусин-Тева, по 10 таблеток у блістері. РП №UA/2447/03/01. Наказ МОЗ України №2036 від 10.11.2022.",
              "<br>Інформація про лікарський засіб. Для професійної діяльності медичних/фармацевтичних працівників. ",
              "1 таблетка містить: бутамірату цитрату 4 мг, гвайфенезину 100 мг",
              "Лікувальні властивості: Лікування сухого подразливого нападоподібного кашлю різного походження. Можливі побічні реакції: анорексія, головний біль, сонливість, утруднене дихання. Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу. ",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284. ",
              "CBG-UA-01304",
            ]
          },
        }
      },
      {
        id: '14',
        name: 'Загадкова беладона',
        disable: false,

        quiz: {
          question: [
            {
              name: "Антибактеріального",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Антигістамінного",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Знеболювального",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Сечогінного",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "У таємничих тінях стародавніх лісів, де тишу порушує лише шепіт природи, росте овіяна легендами та міфами рослина — <i>Atropa belladonna.</i><br>Венеційські жінки епохи Відродження використовували її сік для зваблення чоловіків, а римляни отруювали ним їжу суперників у боротьбі за владу<sup>1</sup>.<br>Як вважаєте, заради якого ефекту використовували беладону в медицині Середньовіччя?",
          img1: "14-1.jpg",
          text2: 'Згідно з грецькою міфологією, родова назва рослини <i>Atropa</i> походить від імені Атропос, однієї з трьох богинь долі Мойр. Вони були трьома сестрами: Клото пряла нитку долі, Лахесіс вимірювала довжину нитки, а Атропос перерізала її, завершуючи життя. Видова назва <i>Belladonna</i> у перекладі з італійської означає «прекрасна жінка», оскільки венеційські жінки епохи Відродження використовували сік цієї рослини для розширення зіниць, що вважалося спокусливим і привабливим. За легендами відьми натирали своє тіло маззю з беладоною, яка надавала їм відчуття невагомості, польоту та ейфорії<sup>1</sup>. Ці властивості беладони мають наукове обґрунтування. Усі її частини містять нейротоксичні алкалоїди: атропін, скополамін та гіосціамін, які діють як антихолінергічні засоби, спричиняючи порушення діяльності парасимпатичної нервової системи. Саме тому в Середньовіччі її використовували для знеболення та седації пацієнта під час хірургічних втручань, але згодом припинили, бо її застосування вважалося чаклунством<sup>2</sup>.',
          img2: "14-2.jpg",

          text3: {
            text1: "Можна тільки уявити, скільки людей поплатилися життям за вживання беладони в ті часи…<br>Шкода, що у лікарів Середньовіччя не було Диклофенак-Тева Форте 2%, який має виражені знеболювальні та протизапальні властивості<sup>3</sup> та міг би допомогти людям давнини, як допомагає вашим пацієнтам сьогодні!",
            items: [
              {
                title1: "Гель Диклофенак-Тева Форте 2% показаний для<sup>3</sup>: ",
                text2: "",
                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Лікування болю, запалення та набряку при:</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>— </span>" +
                  "<span class='text'>Пошкодженні м’яких тканин: травми сухожиль, зв’язок, м’язів, суглобів та біль у спині</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>— </span>" +
                  "<span class='text'>Локалізованих формах ревматизму м’яких тканин: тендиніт, бурсит, плечовий синдром та періартропатія</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Симптоматичного лікування остеоартриту дрібних та середніх суглобів, що розташовані поверхнево, таких як суглоби пальців або колінні суглоби</span>" +
                  "</p>",
                img3: "14-3.png",
              }
            ],

            links: [
              "1. A Note from the Collections – From Assassinations to Witches’ Brews: The Troubled History of the Belladonna Plant. Available at: <a href='https://imss.org/2020/05/01/a-note-from-the-collections-from-assassinations-to-witches-brews-the-troubled-history-of-the-belladonna-plant/' target='_blank'>https://imss.org/2020/05/01/a-note-from-the-collections-from-assassinations-to-witches-brews-the-troubled-history-of-the-belladonna-plant/</a> (Last access: 09.11.2023). ",
              "2. Atropa Belladonna. Available at: <a href='https://www.sciencedirect.com/topics/neuroscience/atropa-belladonna' target='_blank'>https://www.sciencedirect.com/topics/neuroscience/atropa-belladonna</a> (Last access: 09.11.2023). ",
              "3. Інструкція для медичного застосування лікарського засобу Диклофенак-Тева Форте 2%, гель 2 %. РП №UA/19275/01/01. Наказ МОЗ України №1979 від 17.11.2023. ",
              "<br>Інформація про лікарський засіб. РП №UA/19275/01/01. Для професійної діяльності медичних/фармацевтичних працівників. ",
              "Характеристики: 1 г гелю містить диклофенаку діетиламіну 23,2 мг, що еквівалентно диклофенаку натрію 20 мг. ",
              "Лікувальні властивості: засоби, що застосовуються місцево у разі суглобового та м’язового болю. Нестероїдні протизапальні засоби місцевого застосування. Диклофенак. ",
              "Найчастішими побічними реакціями є: дерматит (зокрема контактний дерматит), висип, еритема, екзема, свербіж. ",
              "Із повним переліком побічних реакцій можна ознайомитися в інструкції для медичного застосування лікарського засобу Диклофенак-Тева Форте 2%.",
              "<br> КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
              "CBG-UA-01304",
            ]
          },
        }
      },
      {
        id: '15',
        name: 'Равликовий сироп',
        disable: false,
        quiz: {
          question: [
            {
              name: "Хронічний панкреатит",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Виразкова хвороба",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Жовчнокам'яна хвороба",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Целіакія",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "Шлунковий слиз, що покриває поверхневі епітеліальні клітини шаром товщиною 0,2–0,6 мм, відіграє надзвичайно важливу роль у процесах захисту слизової оболонки<sup>1</sup>. Шлунковий слиз можна порівняти зі слизом, що виробляють равлики.<br>У часи Середньовіччя з настанням світанку люди виходили у свої сади і розпочинали щоденну працю. Вони обережно прочісували кущі, заглядали під листя в пошуках маленьких слизьких «скарбів» — садових равликів. Їх вважали справжнім делікатесом, а також використовували в медицині<sup>2</sup>.<br>Як гадаєте, для лікування якого захворювання застосовували равликів? ",
          img1: "15-1.jpg",
          text2: 'Люди видаляли мушлі та розрізали равликів, а потім клали їх у мішок із цукром. Під ним ставили посудину, щоб збирати сироп, який утворювався при розчиненні цукру слизом равликів і просочувався крізь мішок. Цю рідину використовували внутрішньо не лише при виразковій хворобі шлунка та дванадцятипалої кишки, а й при кашлі, болю в горлі, та зовнішньо при опіках і ранах. Вважається, що равликовий слиз стимулює вироблення колагену та еластину, тому сучасні косметичні компанії часто використовують його у складі косметичних засобів<sup>1</sup>.',
          img2: "15-2.jpg",

          text3: {
            text1: "Добре, що тепер не потрібно робити сироп із равликів, адже в Лінійці бренду Тева є Пантопразол-Тева, який інгібує секрецію соляної кислоти в шлунку шляхом блокади протонних помп парієтальних клітин!<sup>2</sup>",
            items: [
              {
                title1: "Показання<sup>2</sup>",
                text2: "",
                text3: "<p class='list'>" +
                  "<span class='new-text'>Дорослі та діти віком від 12 років:<br></span>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Рефлюкс-езофагіт</span>" +
                  "</p>" +
                  "<br>" +
                  "<p class='list'>" +
                  "<span class='new-text'>Дорослі:<br></span>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Ерадикація <i>Helicobacter pylori (H. рylori)</i> у пацієнтів із H. рylori-асоційованими виразками шлунка та дванадцятипалої кишки у комбінації з відповідними антибіотиками</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Виразка дванадцятипалої кишки</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Виразка шлунка</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Синдром Золлінгера — Еллісона та інші гіперсекреторні патологічні стани</span>" +
                  "</p>",
                img3: "15-3.png",
              }
            ],

            links: [
              "1. Фізіологія. Секреція слизу. Режим доступу: <a href='https://empendium.com/ua/manual/chapter/B72.III.D.1.' target='_blank'>https://empendium.com/ua/manual/chapter/B72.III.D.1.</a> (останнє звернення: 08.02.2024).",
              "2. Crazy Potions and Nasty Nostrums: Six Bizarre Medieval Medicines. Available at: <a href='https://www.historyonthenet.com/crazy-potions-and-nasty-nostrums-six-bizarre-medieval-medicines' target='_blank'>https://www.historyonthenet.com/crazy-potions-and-nasty-nostrums-six-bizarre-medieval-medicines</a> (Last access: 11.01.2024).",
              "3. Інструкція для медичного застосування лікарського засобу Пантопразол-Тева, таблетки гастрорезистентні по 40 мг. РП №UA/16874/01/01.",
              "<br>Інформація про лікарський засіб. Для професійної діяльності медичних і фармацевтичних працівників. ",
              "Характеристики: 1 таблетка гастрорезистентна містить пантопразолу 40 мг у вигляді пантопразолу натрію сесквігідрату.",
              "Лікувальні властивості: засоби для лікування кислотозалежних захворювань. Засоби для лікування пептичної виразки та гастроезофагеальної рефлюксної хвороби. Інгібітори протонної помпи. Пантопразол.",
              "Найчастішими побічними реакціями є: діарея і головний біль, залозисті поліпи дна шлунка (доброякісні).",
              "Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу Пантопразол-Тева.",
              "<br>CBG-UA-01372",
              "КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284."
            ]
          },
        }
      },
      {
        id: '16',
        name: 'Панацея Середньовіччя',
        disable: false,

        quiz: {
          question: [
            {
              name: "Кровопускання",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Молочні трансфузії",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Лоботомія",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Ртутна мазь",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "Ще в часи золотої доби Афін цей метод вважався порятунком від усіх захворювань. Він базувався на «гуморальній теорії» Гіппократа, згідно з якою здоров’я людини визначалося балансом чотирьох рідин — «гуморів» — крові, флегми, жовтої та чорної жовчі. Відповідно, порушення цього балансу вважалося причиною всіх проблем зі здоров’ям<sup>1</sup>.<br>Як вважаєте, що це був за метод? ",
          img1: "16-1.jpg",
          text2: 'Кровопускання здійснювали шляхом венесекції, а також за допомогою п\'явок. Попри те, що цей метод легко міг призвести до фатальних наслідків від втрати крові, він залишався поширеним та популярним тривалий час. Середньовічні лікарі призначали кровопускання для лікування всього — від ангіни до чуми, а деякі перукарі включали його до переліку своїх послуг поряд зі стрижками та голінням. У ХХ столітті кровопускання все ще застосовували для лікування гіпертензії, важкої серцевої недостатності та набряку легень, але на щастя від цих показань теж відмовилися<sup>1,2</sup>.',
          img2: "16-2.jpg",

          text3: {
            text1: "Добре, що тепер не треба вдаватися до кровопускання, оскільки в Лінійці бренду Тева є Валсартан Н-Тева, препарат валсартану та гідрохлоротіазиду, який ефективно регулює артеріальний тиск!<sup>3</sup>",
            items: [
              {
                title1: "Показаний для лікування<sup>3</sup>: ",
                text2: "",
                text3: "Есенціальної артеріальної гіпертензії у пацієнтів, тиск крові яких відповідно не регулюється монотерапією",
                img3: "16-3.png",
              }
            ],

            links: [
              "1. 7 Unusual Ancient Medical Techniques. Available at: <a href='https://www.history.com/news/7-unusual-ancient-medical-techniques' target='_blank'>https://www.history.com/news/7-unusual-ancient-medical-techniques</a> (Last access: 12.01.2024). ",
              "2. Colović N., Leković D., Gotić M. Treatment by bloodletting in the past and present. Srp Arh Celok Lek. 2016; 144(3-4):240-8. Serbian. PMID: 27483574.",
              "3. Інструкція для медичного застосування лікарського засобу Валсартан Н-Тева, таблетки, вкриті плівковою оболонкою, по 80 мг/12,5 та 160/12,5 мг. РП №UA/5743/01/01, №UA/5743/01/02. ",
              "<br>Інформація про лікарський засіб. Для професійної діяльності медичних і фармацевтичних працівників. ",
              "Характеристики: 1 таблетка містить валсартану 80 мг, гідрохлоротіазиду 12,5 мг або валсартану 160 мг, гідрохлоротіазиду 12,5 мг.",
              "Лікувальні властивості: антагоністи ангіотензину ІІ та діуретики. Валсартан та діуретики.",
              "Найчастішими побічними реакціями є: головний біль, втома, запаморочення, кашель, риніт, фарингіт, інфекції верхніх дихальних шляхів, діарея, біль у спині, артралгія, еректильна дисфункція. ",
              "Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу Валсартан Н-Тева. ",
              "<br>CBG-UA-01357",
              "КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
            ]
          },
        }
      },
      {
        id: '17',
        name: 'Камінь вічного життя ',
        disable: false,
        quiz: {
          question: [
            {
              name: "Парацетамол",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "дротаверин",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Пантопразол",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Валсартан",
              correct: true,
              disable: false,
              select: false,
            }
          ],

          text1: "У часи, коли наука та магія були нерозривно пов'язані, виникла легенда про філософський камінь. В уявленнях середньовічних алхіміків цей невідомий хімічний реактив був необхідний для перетворення металів у золото або срібло. Цій речовині приписувалася могутня сила: вона мала не лише перетворювати метали, а й слугувати універсальними ліками. Її розчин, так званий золотий напій (лат. <i>aurum potabile</i>), застосований у малих дозах, мав би зцілювати від усіх захворювань та подовжувати життя<sup>1</sup>.<br>Як гадаєте, який лікарський засіб, немов золотий еліксир, може сповільнити прогресування серцевої недостатності? ",
          img1: "17-1.jpg",
          text2: 'Валсартан є активним специфічним антагоністом рецепторів ангіотензину ІІ. Застосування валсартану приводить до<sup>2</sup>:' +
            '<br><span class=\'circle\'>● </span>Зменшення випадків госпіталізації внаслідок СН ' +
            '<br><span class=\'circle\'>● </span>Уповільнення прогресування та зменшення симптомів СН' +
            '<br><span class=\'circle\'>● </span>Поліпшення функціонального класу за класифікацією NYHA' +
            '<br><span class=\'circle\'>● </span>Збільшення фракції викиду' +
            '<br><span class=\'circle\'>● </span>Покращення якості життя',
          img2: "17-2.jpg",

          text3: {
            text1: "Добре, що сьогодні вам не треба шукати філософський камінь, бо в Лінійці бренду Тева є Валсартан-Тева!",
            items: [
              {
                title1: "Показання до застосування<sup>2</sup>: ",
                text2: "",
                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Лікування АГ у дорослих та дітей віком від 6 років</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Лікування симптоматичної СН або безсимптомної систолічної дисфункції лівого шлуночка після нещодавно перенесеного інфаркту міокарда</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Лікування симптоматичної СН у дорослих пацієнтів, коли не можна застосовувати іАПФ</span>" +
                  "</p>",
                img3: "17-3.png",
              }
            ],

            links: [
              "NYHA (The New York Heart Association) — Нью-Йоркська асоціація кардіологів; АГ — артеріальна гіпертензія; іАПФ — інгібітори ангіотензинперетворювального ферменту; СН — серцева недостатність",
              "1. Britannica. Philosopher’s stone alchemy. Available at: <a href='https://www.britannica.com/topic/philosophers-stone' target='_blank'>https://www.britannica.com/topic/philosophers-stone</a> (Last access: 18.01.2024). ",
              "2. Інструкція для медичного застосування лікарського засобу Валсартан-Тева, таблетки, вкриті плівковою оболонкою, по 80 та 160 мг. РП №UA/5463/01/02, №UA/5463/01/03.",
              "<br>Інформація про лікарський засіб. Для професійної діяльності медичних і фармацевтичних працівників. ",
              "Характеристики: 1 таблетка містить валсартану 80 або 160 мг.",
              "Лікувальні властивості: прості препарати антагоністів ангіотензину ІІ.",
              "Найчастішими побічними реакціями є: запаморочення, постуральне запаморочення, ортостатична гіпотензія, підвищення рівня креатиніну та сечовини крові. ",
              "Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу Валсартан-Тева. ",
              "<br>CBG-UA-01355",
              "КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
            ]
          },
        }
      },
      {
        id: '18',
        name: 'Оксимел для захворювання у горлі',
        disable: false,
        quiz: {
          question: [
            {
              name: "Вино й мед",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Мед й оцет",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Молоко і масло",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Трави й оцет",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "В античному місті Кос, де морські хвилі лагідно обіймали береги, жив легендарний грецький лікар Гіппократ. Він був справжнім мудрецем, який вивчав таємниці людського тіла і душі. Його ім'я було відоме далеко за межами міста, адже він володів знаннями, що здавалися магічними. Одного разу до нього звернувся чоловік, якого турбував кашель та захворювання горла.<br>Гіппократ, знаючи про цілющу силу природи, вирішив приготувати особливий еліксир — Оксимел<sup>1</sup>. Які компоненти входили до цього лікувального напою? ",
          img1: "18-1.jpg",
          text2: 'Оксимел був популярним лікувальним засобом, що містив мед й оцет. Його застосовували у Стародавній Греції для лікування різноманітних хвороб<sup>1</sup>. Термін «оксимел» з грецької перекладається як «кислота і мед». Вважалося, що Оксимел має антиоксидантні та протизапальні властивості. Це обґрунтовує його застосування ще у давні часи для лікування кашлю та захворювання горла<sup>2</sup>.',
          img2: "18-2.jpg",

          text3: {
            text1: "Якби Гіппократ знав про Тевалор-Тева для лікування захворювань горла з широкої Лінійки бренду Тева з місцевою антисептичною, антибактеріальною і протигрибковою дією<sup>3</sup>, то міг би допомогти людям античної Греції із запальними процесами ротової порожнини та горла!",
            items: [
              {
                title1: "Показання<sup>3</sup>",
                text2: "",
                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Місцеве антисептичне лікування запальних процесів ротової порожнини і горла, спричинених чутливими до препарату збудниками (катаральні, виразкові, фіброзні гінгівіти, афтозні стоматити, кандидоз, фарингіти, тонзиліти, ларингіти і глосити)</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Комплексне лікування більш тяжких інфекцій горла у поєднанні з антибіотиками та сульфонамідами</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Профілактика інфекцій до та після оперативних втручань у порожнині рота і горла</span>" +
                  "</p>",
                img3: "18-3.png",
              }
            ],

            links: [
              "1. Johnston C.S., Gaas C.A. Vinegar: medicinal uses and antiglycemic effect. MedGenMed. 2006; 8(2):61. PMID: 16926800; PMCID: PMC1785201.",
              "2. Narges Sharifi Darani, Mahdi Alizadeh Vaghasloo, Asma Kazemi, et al. Oxymel: A systematic review of preclinical and clinical studies. Heliyon. 2023; 9(12):e22649. ISSN 2405-8440.",
              "<a href='https://doi.org/10.1016/j.heliyon.2023.e22649' target='_blank'>https://doi.org/10.1016/j.heliyon.2023.e22649</a>",
              "3. Інструкція для медичного застосування лікарського засобу Тевалор-Тева, таблетки для смоктання. РП №UA/4271/01/01.",
              "<br>Інформація про лікарський засіб. Для професійної діяльності медичних/фармацевтичних працівників. ",
              "Характеристики: 1 таблетка містить деквалінію хлориду 0,25 мг; кислоти аскорбінової 30 мг.",
              "Лікувальні властивості: препарати, що застосовуються при захворюваннях горла. Різні антисептики. Найчастішими побічними реакціями є: нудота, блювання, відчуття печіння і подразнення у горлі, сухість у порожнині рота, дисбактеріоз ротової порожнини. Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу Тевалор-Тева. ",
              "<br>КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284. ",
              "CBG-UA-01439",
            ]
          },
        }
      },
      {
        id: '19',
        name: 'Випадкова знахідка',
        disable: false,
        quiz: {
          question: [
            {
              name: "Тахікардії",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Гіпотензії",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Гіпертензії",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Брадикардії",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "Під час Другої Світової війни американський лікар Едвард Фрейс спостерігав за добровольцями, які брали участь у антималярійній програмі. Вони приймали пентахін. Фрейс помітив, що прийом цього препарату в учасників програми спричиняє певний гемодинамічний ефект, що могло б бути великим відкриттям у світі кардіології<sup>1</sup>.<br>Як вважаєте, до чого саме призводив пентахін? ",
          img1: "19-1.jpg",
          text2: 'Едвард Фрейс помітив, що у здорових добровольців антималярійної програми розвивалася ортостатична гіпотензія після кількох днів прийому пентахіну. Його почали застосовувати для лікування гіпертензії в експериментальному режимі.<br>Пентахін знижував артеріальний тиск у осіб із гіпертонією, проте мав токсичні побічні ефекти, які виключали його рутинний прийом. Тому широкого застосування цей метод гіпотензивної терапії не набув<sup>1</sup>.',
          img2: "19-2.jpg",

          text3: {
            text1: "Добре, що в Лінійці бренду Тева є Лерканідипін-Тева, ефективний антагоніст кальцію дигідропіридинової групи<sup>2</sup>, створений для контролю артеріального тиску!",
            items: [
              {
                title1: "",
                text2: "",
                text3: "Показаний для лікування есенціальної гіпертензії легкого або помірного ступеня тяжкості<sup>2</sup>",
                img3: "19-3.png",
              }
            ],

            links: [
              "1. National Library of Medicine. Edward D. Freis. Early Career and Work with Antihypertensive Drugs, 1940-1949. Available at: <a href='https://profiles.nlm.nih.gov/spotlight/xf/feature/drugs' target='_blank'>https://profiles.nlm.nih.gov/spotlight/xf/feature/drugs</a> (Last access: 18.01.2024). ",
              "2. Інструкція для медичного застосування лікарського засобу Лерканідипін-Тева, таблетки, по 10 та 20 мг. РП №UA/16977/01/02, №UA/16977/01/01.",
              "<br>Інформація про лікарський засіб. Для професійної діяльності медичних і фармацевтичних працівників. ",
              "Характеристики: 1 таблетка містить лерканідипіну 10 або 20 мг.",
              "Лікувальні властивості: селективні антагоністи кальцію з переважною дією на судини. Похідні дигідропіридину.",
              "Найчастішими побічними реакціями є: головний біль, тахікардія, посилене серцебиття, припливи, периферичні набряки. ",
              "Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу Лерканідипін-Тева. ",
              "<br>CBG-UA-01358",
              "КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284.",
            ]
          },
        }
      },
      {
        id: '20',
        name: 'Джерело нескінченної молодості',
        disable: false,
        quiz: {
          question: [
            {
              name: "Небіволол",
              correct: true,
              disable: false,
              select: false,
            },
            {
              name: "Ібупрофен",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Омепразол",
              correct: false,
              disable: false,
              select: false,
            },
            {
              name: "Метоклопрамід",
              correct: false,
              disable: false,
              select: false,
            }
          ],

          text1: "У далекому 1513 році Хуан Понсе де Леон, іспанський конкістадор, вирушив у найзагадковішу експедицію свого життя. Він шукав не золото і не славу, а щось набагато цінніше — фонтан вічної молодості. Завдяки розпитуванню індіанців він довідався про існування чудодійного джерела на острові Біміні, що лежить на північ від Пуерто-Рико. Коли його корабель прибув до берегів, він вирішив, що це острів і назвав його Флоридою через розкішну тропічну флору, а також тому, що відкриття «квітучої землі» сталося на пасхальний тиждень (<i>Pascua Florida</i>). Хуану Понсе де Леону не вдалося знайти фонтан молодості і ця історія залишилася лише легендою<sup>1</sup>.<br>Як вважаєте, який лікарський засіб може подовжувати тривалість життя, як краплина води з джерела вічної молодості? ",
          img1: "20-1.jpg",
          text2: 'Небіволол — це селективний блокатор β-адренорецепторів. Було проведене плацебо-контрольоване дослідження за участю 2128 пацієнтів віком ≥70 років зі стабільною хронічною серцевою недостатністю зі зниженням або без зниження фракції викиду лівого шлуночка. Застосування небівололу в цих пацієнтів, як доповнення до стандартної терапії, суттєво подовжувало час до настання смерті або госпіталізації, пов’язаної із серцево-судинною патологією. А також спостерігалося зниження частоти випадків раптової смерті<sup>2</sup>. ',
          img2: "20-2.jpg",

          text3: {
            text1: "Добре, що в Лінійці бренду Тева є Небіволол-Тева! Хай він і не дає вічну молодість, але здатний зробити життя ваших пацієнтів із артеріальною гіпертензією довшим!",
            items: [
              {
                title1: "Показання<sup>2</sup>",
                text2: "",

                text3: "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Есенціальна артеріальна гіпертензія</span>" +
                  "</p>" +
                  "<p class='list'>" +
                  "<span class='circle'>● </span>" +
                  "<span class='text'>Стабільна хронічна серцева недостатність легкого і середнього ступеня як доповнення до стандартної терапії для пацієнтів віком від 70 років</span>" +
                  "</p>",
                img3: "20-3.png",
              }
            ],

            links: [
              "1. Biography. Juan Ponce de León. Available at: <a href='https://www.biography.com/history-culture/juan-ponce-de-leon' target='_blank'>https://www.biography.com/history-culture/juan-ponce-de-leon</a>  (Last access: 17.01.2024). ",
              "2. Інструкція для медичного застосування лікарського засобу Небіволол-Тева, таблетки, по 5 мг. РП №UA/14877/01/01.",
              "<br>Інформація про лікарський засіб. Для професійної діяльності медичних і фармацевтичних працівників.",
              "Характеристики: 1 таблетка містить небівололу 5 мг.",
              "Лікувальні властивості: селективні блокатори β-адренорецепторів.",
              "Найчастішими побічними реакціями є: головний біль, запаморочення, парестезія, задишка, запор, нудота, діарея, підвищена втомлюваність, набряки. ",
              "Повний перелік побічних реакцій міститься в інструкції для медичного застосування лікарського засобу Небіволол-Тева. ",
              "<br>CBG-UA-01476",
              "КОНТАКТИ: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284."
            ]
          },
        }
      },
    ],

    usefulTotalPages: {
      current: null,
      totalPage: null,
      totalItem: null
    },
  },

  getters: {

    getBgMusicTime(state) {
      return state.bgMusicTime
    },
    getSound(state) {
      return state.sound
    },

    getMenu(state) {
      return state.menu
    },

    getTotalHeight(state) {
      return state.totalHeight
    },

    getRowContent(state) {
      return state.rowContent
    },

    getFilterNos(state) {
      return state.filterNos
    },

    getCurrentProduction(state) {
      return state.currentProduction
    },

    getMoreProduction(state) {
      return state.moreProduction
    },

    getAnswerData(state) {
      return state.answerData
    },

    getLoader(state) {
      return state.loader
    },

    getAllFilter(state) {
      return state.allFilter
    },
    getRecipe(state) {
      return state.recipe
    },

    getNonRecipe(state) {
      return state.nonRecipe
    },

    getPharm(state) {
      return state.pharm
    },

    getPageNum(state) {
      return state.pageNum
    },

    getGamePopup(state) {
      return state.gamePopup
    },

    getArticleNosologies(state) {
      return state.articleNosologies
    },

    getArticles(state) {
      return state.articles
    },

    getArticle(state) {
      return state.article
    },
    getArticleNum(state) {
      return state.articleNum
    },

    getRelatedArticle(state) {
      return state.relatedArticle
    },
    getRelatedDrugs(state) {
      return state.relatedDrugs
    },
    getUsefulTotalPages(state) {
      return state.usefulTotalPages
    },

    getQuizHubStage(state) {
      return state.quizHubStage
    },
    getQuizData(state) {
      return state.quizData
    },

    getCurrentQuizData(state) {
      return state.quizData
    }
  },

  mutations: {
    setBgMusicTime(state, time) {
      state.bgMusicTime = time
    },

    setHeightContent(state) {

      let headerH = document.getElementById('header-wrapper').offsetHeight
      let footerH = document.getElementById('footer-wrapper').offsetHeight


      state.totalHeight = (headerH + footerH) + 'px';

      document.documentElement.style.setProperty('--height_content', state.totalHeight);
      document.documentElement.style.setProperty('--height_header', headerH + "px");
    },

    setMenu(state) {
      state.menu = !state.menu
    },

    setDataContent(state, data) {
      state.rowContent = data
    },

    setFilterNos(state, data) {
      state.filterNos = data
    },

    setCurrentProduction(state, data) {
      state.currentProduction = data
    },

    setMoreProduction(state, data) {
      state.moreProduction = data
    },

    setAnswerData(state, data) {
      state.answerData = data
    },

    setLoader(state, data) {
      state.loader = data;
    },

    setRowData(state, data) {
      state.rowData = data
    },

    selAllFilters(state, data) {
      state.allFilter = data
    },

    setRecipe(state, data) {
      state.recipe.isActive = data.isActive;
      state.recipe.disable = data.disable;
    },

    setNonRecipe(state, data) {
      state.nonRecipe.isActive = data.isActive;
      state.nonRecipe.disable = data.disable;
    },

    setPharm(state, data) {
      state.pharm.isActive = data.isActive;
      state.pharm.disable = data.disable;
    },

    setDataForFilters(state, data) {
      state.dataForFilters = data
    },

    setPageNumSingle(state, nextPage) {
      state.pageNum = [nextPage]
    },

    setPageNumMore(state, nextPage) {
      state.pageNum.push(nextPage)
    },

    setGamePopup(state) {
      state.gamePopup = !state.gamePopup
    },

    setArticleNosologies(state, data) {
      state.articleNosologies = data
    },

    setArticles(state, res) {
      state.articles = res
    },

    setArticle(state, res) {
      state.article = res
    },

    setArticleNumSingle(state, nextPage) {
      state.articleNum = [nextPage]
    },
    setArticleNumMore(state, nextPage) {
      state.articleNum.push(nextPage)
    },

    setRelatedArticle(state, res) {
      state.relatedArticle = res
    },
    setRelatedDrugs(state, res) {
      state.relatedDrugs = res
    },

    setQuizHubStage(state, data) {
      state.quizHubStage = data
    },

    setSound(state, data) {
      state.sound = data
    },
    setUsefulTotalPages(state, res) {
      state.usefulTotalPages.current = res.current
      state.usefulTotalPages.totalPage = res.total
      state.usefulTotalPages.totalItem = res.totalItem


    }
  },

  actions: {
    doSound({commit, dispatch, state}, data) {
      commit("setSound", data)
    },
    doBgMusicTime({commit, dispatch, state}, time) {
      commit("setBgMusicTime", time)
    },

    doHeightContent(context) {
      context.commit('setHeightContent');
    },

    doMenu(context) {
      context.commit('setMenu');
    },

    doProduction({commit, dispatch, state}, data) {
      let lF = '';
      let sF = '';
      let rF = '';
      let aF = '';
      let nF = '';

      commit('setLoader', true);

      let elementArray = []

      if (Object.keys(state.route.query).length !== 0) {

        if (state.route.query.l !== undefined) {
          lF = state.route.query.l;
        }

        if (state.route.query.s !== undefined) {
          sF = state.route.query.s;
        }

        if (state.route.query.r !== undefined) {
          rF = state.route.query.r;
          if (rF === '0') {
            commit('setNonRecipe', {isActive: true, disable: false});
          } else if (rF === '1') {
            commit('setRecipe', {isActive: true, disable: false});
          }
        }

        if (state.route.query.a !== undefined) {
          aF = state.route.query.a;
          if (aF === '1') {
            commit('setPharm', {isActive: true, disable: false});
          }
        }

        if (state.route.query.n !== undefined) {
          nF = state.route.query.n;

          if (nF === '') {
            elementArray = []
          } else {
            nF.split(',').forEach((value) => {
              elementArray.push(parseInt(value));
            })
          }
        }
      }

      axios.get(url + "/api/getDrugs?locale=ukr" + '&l=' + lF + '&s=' + sF + '&n=' + nF + '&r=' + rF + '&a=' + aF).then((res) => {

        let nos = []

        res.data.filters.nosologies.forEach((el) => {

          el.disabled = false

          el.active = false

          el.hide = false;

          elementArray.forEach((secondEl) => {
            if (secondEl === el.id) {
              el.active = true
            }
          })

          nos.push(el)
        })

        commit('setFilterNos', nos);

        commit('setRowData', res.data.data)

        commit('setDataForFilters', res.data.data)

        dispatch('sort', data)

        dispatch("checkDependency", data.value2)

        setTimeout(() => {
          commit('setLoader', false);
        }, 250)
      })
    },

    doCurrentProduction(context, {id, variation_id}) {
      context.commit('setLoader', true);
      axios.get(url + "/api/getDrug/" + id + "?locale=ukr").then((res) => {


        let baseProduction = res.data;
        let variation = variation_id;
        let variationProduction = res.data.variations.find(z => variation !== undefined && z.id == variation);
        let variationProduction2 = variationProduction ? {...variationProduction} : {...res.data.variations[0]};
        let drugForm = !baseProduction.variations ? [] : baseProduction.variations.filter(z => z.id !== variationProduction.id).map(z => {
          return {
            ...z.drug_form,
            variation_id: z.id,
            drug_dosage: z.drug_dosage,
          }
        })

        let fullData = {
          row: baseProduction,
          prod: variationProduction2,
          form: drugForm
        }

        context.commit('setCurrentProduction', fullData);

        axios.get(url + "/api/getRelatedDrugs?nosology=" + baseProduction.nosology.id + "&locale=ukr").then((res) => {
          let moreProduction = res.data.filter((el, index) => {
            return index <= 1
          });

          context.commit('setMoreProduction', moreProduction);
        })


        axios.get(url + "/api/getArticles?locale=ukr&a=&d=" + fullData.row.id + "&n=").then((res) => {
          context.commit("setRelatedArticle", res.data.articles)
        })


        document.title = fullData.row.translation.name;

        setTimeout(() => {
          context.commit('setLoader', false);
        }, 250)
      })
    },

    doAnswerData(context) {
      context.commit('setLoader', true);
      axios.get(url + "/api/mnn?locale=ukr").then((res) => {

        axios.get(url + "/api/mnn?locale=ru").then((secondRes) => {
          let img = res.data;

          let data = {
            img: null,
            imgMob: null,
            linkText: null,
          }

          data.img = Object.values(img)
          data.linkText = Object.keys(img)
          data.imgMob = Object.values(secondRes.data)

          context.commit('setAnswerData', data);

          setTimeout(() => {
            context.commit('setLoader', false);
          }, 250)
        })

      })
    },

    changeRecipe({commit}, data) {
      commit('setRecipe', {isActive: data, disable: false});
    },

    changeNonRecipe({commit}, data) {
      commit('setNonRecipe', {isActive: data, disable: false});
    },

    changePharm({commit}, data) {
      commit('setPharm', {isActive: data, disable: false});
    },

    checkDependency({commit, dispatch, state}, action) {

      commit('setRecipe', {isActive: state.recipe.isActive, disable: false});
      commit('setNonRecipe', {isActive: state.nonRecipe.isActive, disable: false});
      commit('setPharm', {isActive: state.pharm.isActive, disable: false});

      let array = []

      state.dataForFilters.forEach((el) => {
        if (!el.hide) {
          array.push(el)
        }
      })

      if (array.filter(e => e.with_recipe).length === 0) {
        commit('setRecipe', {isActive: false, disable: true});
      }

      if (array.filter(e => !e.with_recipe).length === 0) {
        commit('setNonRecipe', {isActive: false, disable: true});
      }

      if (array.filter(e => e.available_medication).length === 0) {
        commit('setPharm', {isActive: false, disable: true});
      }

      state.filterNos.forEach((el) => {
        el.disabled = false;
        if (state.recipe.isActive || state.nonRecipe.isActive || state.pharm.isActive || action !== '') {
          if (array.filter(e => e.nosology.id === el.id).length === 0) {
            el.disabled = true
          }
        }
      })

    },

    getNewPage({commit, dispatch, state}, goToPage) {
      if (goToPage.type === 'single') {

        commit('setPageNumSingle', goToPage.page)

        dispatch('Paginator', {
          items: state.rowData.filter((el) => {
            return !el.hide
          }), page: goToPage.page, per_page: itemOnPage
        }).then((res) => {

          commit('setDataContent', res);

          window.scrollTo(0, 0)

        })
      } else if (goToPage.type === 'more') {

        commit('setPageNumMore', goToPage.page)

        dispatch('Paginator2', {
          items: state.rowData.filter((el) => {
            return !el.hide
          }), page: goToPage.page, per_page: itemOnPage
        }).then((res) => {

          commit('setDataContent', res);

        })
      }
    },

    sort({commit, dispatch, state}, data) {

      dispatch('sortText', data)
      dispatch('sortDrop', data)

      let dataPage = {page: 1, type: "single"}

      dispatch('getNewPage', dataPage)

    },

    sortDrop({commit, dispatch, state}, data) {

      if (data.value1 === 'За категоріями') {
        state.rowData.sort((x, y) => {
          return x.nosology.name.localeCompare(y.nosology.name);
        });
      }

      if (data.value1 === 'За популярністю') {
        state.rowData.sort((a, b) => {
          return b.popularity - a.popularity
        });
      }

      if (data.value1 === 'За алфавітом') {
        state.rowData.sort((x, y) => {
          return x.translation.name.localeCompare(y.translation.name);
        });
      }

    },

    sortText({commit, dispatch, state}, data) {
      state.rowData.forEach(el => {
        el.hide = false

        if (!el.translation.name.toLowerCase().includes(data.value2.toLowerCase())) {
          el.hide = true
        }
      })
    },

    Paginator({commit, dispatch}, data) {

      var page = data.page || 1,
        per_page = data.per_page || 8,
        offset = (page - 1) * per_page,
        paginatedItems = data.items.slice(offset).slice(0, per_page),
        total_pages = Math.ceil(data.items.length / per_page);

      return {
        page: page,
        per_page: per_page,
        pre_page: page - 1 ? page - 1 : null,
        next_page: (total_pages > page) ? page + 1 : null,
        total: data.items.length,
        total_pages: total_pages,
        data: paginatedItems,
      };
    },

    Paginator2({commit, dispatch, state}, data) {

      var page = data.page || 1,
        per_page = data.per_page || 8,
        offset = (page - 1) * per_page,
        paginatedItems = data.items.slice(offset).slice(0, per_page),
        total_pages = Math.ceil(data.items.length / per_page);

      let concatArray = state.rowContent.data.concat(paginatedItems)

      return {
        page: page,
        per_page: per_page,
        pre_page: page - 1 ? page - 1 : null,
        next_page: (total_pages > page) ? page + 1 : null,
        total: data.items.length,
        total_pages: total_pages,
        data: concatArray,
      };
    },

    Paginator3({commit, dispatch, state}, data) {

      var page = data.page || 1,
        per_page = data.per_page || 8,
        offset = (page - 1) * per_page,
        paginatedItems = data.items.slice(offset).slice(0, per_page),
        total_pages = Math.ceil(data.items.length / per_page);

      let concatArray = state.articles.data.concat(paginatedItems)

      return {
        page: page,
        per_page: per_page,
        pre_page: page - 1 ? page - 1 : null,
        next_page: (total_pages > page) ? page + 1 : null,
        total: data.items.length,
        total_pages: total_pages,
        data: concatArray,
      };
    },

    GamePopup(context) {
      context.commit('setGamePopup');
    },

    getAllArticles({commit, dispatch, state}, data) {
      let page = data.pageNext.page || 1
      let count = data.countNext || 8
      let aF = '';
      let nF = '';
      let dF = '';

      commit('setLoader', true);

      if (Object.keys(state.route.query).length !== 0) {

        if (state.route.query.a !== undefined) {
          aF = state.route.query.a;
          let elementArray = []

          if (aF === '') {
            elementArray = []
          } else {
            aF.split(',').forEach((value) => {
              elementArray.push(parseInt(value));
            })
          }
        }

        if (state.route.query.n !== undefined) {
          nF = state.route.query.n;
          let elementArray = []

          if (nF === '') {
            elementArray = []
          } else {
            nF.split(',').forEach((value) => {
              elementArray.push(parseInt(value));
            })
          }
        }

        if (state.route.query.d !== undefined) {
          dF = state.route.query.d;
          let elementArray = []

          if (dF === '') {
            elementArray = []
          } else {
            dF.split(',').forEach((value) => {
              elementArray.push(parseInt(value));
            })
          }
        }
      }

      dispatch('addArticlePage', {articlePageData: data.pageNext})

      axios.get(url + "/api/getArticles?locale=ukr&a=" + aF + "&d=" + dF + "&n=" + nF + "&page=" + page + "&count=" + count + "&sort=" + data.dataFilter.value1 + "&title=" + data.dataFilter.value2).then((res) => {
        let HTTPData = res.data

        commit('setUsefulTotalPages', {
          current: data.pageNext.page,
          total: HTTPData.total_pages,
          totalItem: HTTPData.total_records
        })

        commit("setArticleNosologies", HTTPData.filters.nosologies)

        if (data.pageNext.type === 'single') {
          commit('setArticleNumSingle', data.pageNext.page)

          commit("setArticles", HTTPData.articles)
        }
        if (data.pageNext.type === 'more') {
          let moreData = [...state.articles, ...HTTPData.articles]
          commit('setArticleNumMore', data.pageNext.page)

          commit("setArticles", moreData)
        }
      }).then(() => {
        setTimeout(() => {
          commit('setLoader', false);
        }, 400)
      })
    },

    getHTTPArticle({commit, dispatch, state}, id) {
      let adminF = '';

      if (Object.keys(state.route.query).length !== 0) {

        if (state.route.query.admin !== undefined) {
          adminF = "?admin=" + state.route.query.admin;
        }
      }

      axios.get(url + "/api/getArticle/" + id + adminF).then((res) => {

        if (res.data.articles[0].drugs.length !== 0) {
          let array = [];
          res.data.articles[0].drugs.forEach((el) => {
            axios.get(url + "/api/getDrug/" + el.id + "?locale=ukr").then((res) => {
              array.push(res.data)
            })
          })
          commit("setRelatedDrugs", array)
        }

        commit("setArticle", {
          content: JSON.parse(res.data.articles[0].content),
          data: res.data.articles[0]
        })
      })
    },


    addArticlePage({commit, dispatch, state}, {articlePageData}) {
      if (articlePageData.type === 'single') {
      }
      if (articlePageData.type === 'single') {
      }
    },

    getNewArticlesPage({commit, dispatch, state}, goToPage) {
      if (goToPage.type === 'single') {
        // dispatch('getAllArticles', {
        //
        // })
        // commit('setArticleNumSingle', goToPage.page)
        // dispatch('Paginator', {
        //     items: state.articleRow.articles.filter((el) => {
        //         return !el.hide
        //     }), page: goToPage.page, per_page: 8
        // }).then((res) => {
        //     commit("setArticles", res)
        //     window.scrollTo(0, 0)
        // })
      } else if (goToPage.type === 'more') {

        // commit('setArticleNumMore', goToPage.page)
        //
        // dispatch('Paginator3', {
        //     items: state.articleRow.articles.filter((el) => {
        //         return !el.hide
        //     }), page: goToPage.page, per_page: 8
        // }).then((res) => {
        //     commit("setArticles", res)
        // })
      }
    },

    sortArticles({commit, dispatch, state}, {dataFilter, dataArticles}) {

      commit("setArticles", dataArticles)

      dispatch('sortDropArticles', dataFilter)
      dispatch('sortTextArticles', dataFilter)
    },

    sortDropArticles({commit, dispatch, state}, data) {

      // if (data.value1 === 'За замовчуванням') {
      //     state.articles.sort((x, y) => {
      //         return x.priority - y.priority;
      //     });
      // }
      //
      // if (data.value1 === 'За датою публікації') {
      //     let array = state.articles
      //
      //     let sortedActivities = array.sort((a, b) => {
      //         return new Date(b.publishedAt.split(' ')[0].split('.')[1] + '/' + b.publishedAt.split(' ')[0].split('.')[0] + '/' + b.publishedAt.split(' ')[0].split('.')[2]) - new Date(a.publishedAt.split(' ')[0].split('.')[1] + '/' + a.publishedAt.split(' ')[0].split('.')[0] + '/' + a.publishedAt.split(' ')[0].split('.')[2]);
      //     });
      //
      //     state.articles = sortedActivities
      // }
      //
      // if (data.value1 === 'За алфавітом') {
      //     state.articles.sort((x, y) => {
      //         return x.tile.replace('<p>', '').replace('</p>', '').localeCompare(y.tile.replace('<p>', '').replace('</p>'));
      //     });
      // }
    },

    sortTextArticles({commit, dispatch, state}, data) {
      // state.articles.forEach(el => {
      //     el.hide = false
      //
      //     if (!el.tile.replace('<p>', '').replace('</p>', '').toLowerCase().includes(data.value2.toLowerCase())) {
      //         el.hide = true
      //     }
      // })
    },

    doQuizHubStage({commit, dispatch, state}, data) {
      commit('setQuizHubStage', data)
    },
  }
})